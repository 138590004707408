var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.competencia_de || _vm.competencia_ate)?_c('v-row',{staticClass:"mx-auto"},[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"row-cursor-pointer",attrs:{"multi-sort":"","dense":"","headers":_vm.headers,"items":_vm.items,"options":{ multiSort: false },"loading-text":"Carregando tabela","headerProps":{ sortByText: 'Ordenar Por' },"footer-props":{
          itemsPerPageText: 'Itens por Página',
          itemsPerPageOptions: [10, 25, 50, 100],
        },"no-data-text":"Nenhum registro disponível","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":10},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([_vm._l((_vm.chavesIndicadores.concat(
            _vm.indicadoresLiberados
          )),function(headerValue){return {key:_vm.getHeaderSlotName(headerValue),fn:function(item){return [_c('span',{key:headerValue},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.header.text)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.descricaoPorIndicador[headerValue])+" ")])])],1)]}}}),{key:"item.serv_005",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.serv_005)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.nomesServicos[item.serv_005])+" ")])])]}},_vm._l((_vm.indicadoresLiberados),function(indicador){return {key:_vm.getItemSlotName(indicador),fn:function(ref){
          var item = ref.item;
return [_c('span',{key:indicador},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item[indicador]))+" ")])]}}})],null,true)})],1)],1):_vm._e(),_c('servicos-v2-visao-detalhada-dialog-detalhes',{ref:"dialogDetalhes",attrs:{"competencia_de":_vm.competencia_de,"competencia_ate":_vm.competencia_ate,"temas_selecionados":_vm.temas_selecionados,"chavesIndicadores":_vm.chavesIndicadores,"indicadoresPorTema":_vm.indicadoresPorTema,"indicadoresLiberados":_vm.indicadoresLiberados,"authorizedServicos":_vm.authorizedServicos,"authorizedCompanies":_vm.authorizedCompanies,"descricaoPorIndicador":_vm.descricaoPorIndicador,"nomesServicos":_vm.nomesServicos},on:{"updateLoadingDados":function($event){return _vm.$emit('updateLoadingDados', $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }